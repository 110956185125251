const docsAssetUrlMap = {
  '3d-animation':
    'https://res.cloudinary.com/magicsky/image/upload/v1731442085/3D-animation.svg',
  avatar:
    'https://res.cloudinary.com/magicsky/image/upload/v1731442129/avatar.png',
  avatar2:
    'https://res.cloudinary.com/magicsky/image/upload/v1731442128/avatar2.png',
  'bouqs-audio':
    'https://res.cloudinary.com/magicsky/video/upload/v1737149079/bouqs-audio.m4a',
  'han-solo':
    'https://res.cloudinary.com/magicsky/image/upload/v1731442070/hansolo.png',
  'clapping-hands':
    'https://res.cloudinary.com/magicsky/image/upload/v1731442079/clapping-hands.svg',
  businessman:
    'https://res.cloudinary.com/magicsky/image/upload/v1731442081/businessman.png',
  'hair-makeup':
    'https://res.cloudinary.com/magicsky/image/upload/s--Y8g32GsS--/f_auto,q_auto/hair-makeup',
  'darth-vader':
    'https://res.cloudinary.com/magicsky/image/upload/v1731442077/darthvader.png',
  'hexclad-gordon':
    'https://res.cloudinary.com/magicsky/image/upload/v1731442069/hexclad-gordon.png',
  'landscape-video':
    'https://res.cloudinary.com/magicsky/video/upload/v1740088759/landscape-video.mov',
  'leia-organa':
    'https://res.cloudinary.com/magicsky/image/upload/v1731442068/leiaorgana.png',
  musician:
    'https://res.cloudinary.com/magicsky/image/upload/v1731442065/musician.png',
  mountains:
    'https://res.cloudinary.com/magicsky/image/upload/s--d38kOCg2--/f_auto,q_auto/mountains',
  'obi-wan':
    'https://res.cloudinary.com/magicsky/image/upload/v1731442064/obiwan.png',
  'portrait-video':
    'https://res.cloudinary.com/magicsky/video/upload/v1740088760/portrait-video.mov',
  pug: 'https://res.cloudinary.com/magicsky/image/upload/v1731442062/pug.png',
  'qf-logo':
    'https://res.cloudinary.com/magicsky/image/upload/v1731448878/qf-logo.svg',
  skater:
    'https://res.cloudinary.com/magicsky/image/upload/v1731442060/skater.png',
  videographer:
    'https://res.cloudinary.com/magicsky/image/upload/v1731442059/videographer.png',
}

export type DocsAssetName = keyof typeof docsAssetUrlMap

export const getDocsAssetUrl = (asset: DocsAssetName) => docsAssetUrlMap[asset]
